
import { createStore } from 'vuex'

const base_url = process.env.VUE_APP_URL
// const base_url = " https://becoming-bat-certain.ngrok-free.app" //Live
// const base_url = "https://efiber-api.camusat.com"

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))};

export default createStore({
  state: {
    platforms: [],
    teams: [],
    users: [],
    projects: [],
    dispatchprojects: [],
    dispatchMaterials: [],
    mainProjects: [],
    projectTypes: [],
    audits: [],
    user:{},
    subsidiaries: [],
    movementTeams: [],
    workOrder: [],
    selectedWorkOrder: [],
    selectedWorkOrderDetails: [],
    selectedWorkOrderTeamLogs: [],
    selectedWorkOrderCancellationlog: [],
    selectedWorkOrderEditLog: [],
    selectedWorkOrderTasks: [],
    mainProjectsBySubsidiary: [],
    projectsByMainProject: [],
    dispatchProjectsByMainProject: [],
    dispatchProjectByProject: [],
    teamsByDispatchProject: [],
    workorders: [],
    workOrdersByProject: [],
    workOrdersOnSchedule: [],
    ticketsToSchedule: [], // Need to change this to workOrdersToSchedule
    scheduledWorkOrders: [],
    cancelTicketReasons: [],
    rejectTicketReasons: [],
    stopTeamReasons: [],
    problemCategorizations: [],
    ticketProblems:[],
  },
  mutations: { //synchrous
    setPlatforms (state, payload){
      state.platforms = payload
    },
    setTeams (state, payload){
      state.teams = payload
    },
    setUsers (state, payload){
      state.users = payload
    },
    setMainProjects (state, payload){
      state.mainProjects = payload
    },
    setProjects (state, payload){
      state.projects = payload
    },
    setProjectTypes (state, payload){
      state.projectTypes = payload
    },
    setDispatchProjects (state, payload){
      state.dispatchprojects = payload
    },
    setDispatchProjectsByMainProject (state, payload){
      state.dispatchProjectsByMainProject = payload
    },
    setAudits (state, payload){
      state.audits = payload
    },
    setUser (state, payload){
      state.user = payload
    },
    setDispatchMaterials (state, payload){
      state.dispatchMaterials = payload
    },
    setSubsidiaries (state, payload){
      state.subsidiaries = payload
    },
    setMovementTeams (state, payload){
      state.movementTeams = payload
    },
    setWorkOrders (state, payload){
      state.workorders = payload
    },
    setWorkOrder(state, payload){
      state.workOrder = payload
    },
    setSelectedWorkOrder (state, payload){
      // console.log('from mutation', payload)
      state.selectedWorkOrder = payload
    },
    setTicketsToSchedule (state, payload){
      state.ticketsToSchedule = payload
    },
    setScheduledWorkOrders (state, payload){
      state.scheduledWorkOrders = payload
    },
    setWorkOrdersOnSchedule (state, payload){
      state.workOrdersOnSchedule = payload
    },
    setCancelTicketReasons (state, payload){
      state.cancelTicketReasons = payload
    },
    setRejectTicketReasons (state, payload){
      state.rejectTicketReasons = payload
    },
    setStopTeamReasons (state, payload){
      state.stopTeamReasons = payload
    },
    setProblemCategorizations (state, payload){
      state.problemCategorizations = payload
    },
    setTicketProblems (state, payload){
      state.ticketProblems = payload
    },
    setSelectedWorkOrderTeamLogs (state, payload){
      state.selectedWorkOrderTeamLogs = payload
    },
    setSelectedWorkOrderCancellationlog (state, payload){
      state.selectedWorkOrderCancellationlog = payload
    },
    setSelectedWorkOrderEditLog (state, payload){
      state.selectedWorkOrderEditLog = payload
    },
    setSelectedWorkOrderDetails (state, payload){
      state.selectedWorkOrderDetails = payload
    },
    setSelectedWorkOrderTasks (state, payload){
      state.selectedWorkOrderTasks = payload
    },
    setWorkOrdersByProject (state, payload){
      state.workOrdersByProject = payload
    },
    setMainProjectsBySubsidiary (state, payload){
      state.mainProjectsBySubsidiary = payload
    },
    setProjectsByMainProject (state, payload){
      state.projectsByMainProject = payload
    },
    setTeamsByDispatchProject (state, payload){
      state.teamsByDispatchProject = payload
    },
    setDispatchProjectByProject(state, payload){
      state.dispatchProjectByProject = payload
    }
  },

  actions: { //asynchrous
    async fetchPlatforms (state) {
      const res = await fetch(base_url+'/auth/platforms',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPlatforms",data)
    },
    async fetchTeams (state) {
      const res = await fetch(base_url+'/dispatchteam',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setTeams",data)
    },
    async fetchUsers (state) {
      const res = await fetch(base_url+'/users',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setUsers",data)
    },
    async fetchMainProjects (state) {
      const res = await fetch(base_url+'/mainproject',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setMainProjects",data)
    },
    async fetchMainProjectsBySubsidiary (state, payload) {
      const res = await fetch(base_url+'/mainproject/country/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setMainProjectsBySubsidiary",data)
    },
    async fetchProjects (state) {
      const res = await fetch(base_url+'/projects',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProjects",data)
    },
    async fetchProjectsByMainProject (state, payload) {
      const res = await fetch(base_url+'/projects/mainproject/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProjectsByMainProject",data)
    },
    async fetchProjectTypes (state) {
      const res = await fetch(base_url+'/project/type',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProjectTypes",data)
    },
    async fetchDispatchProjects (state) {
      const res = await fetch(base_url+'/dispatchproject',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setDispatchProjects",data)
    },
    async fetchDispatchProjectsByMainProject (state, payload) {
      const res = await fetch(base_url+'/dispatchproject/mainproject/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      console.log('dispatch data', data)
      state.commit("setDispatchProjectsByMainProject",data)
    },
    async fetchTeamsByDispatchProject (state, payload) {
      const res = await fetch(base_url+'/dispatchteam/teams/project/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      console.log('teams by dispatch project', data)
      state.commit("setTeamsByDispatchProject",data)
    },
    async fetchDispatchProjectByProject (state, payload) {
      const res = await fetch(base_url+'/dispatchproject/project/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setDispatchProjectByProject",data)
    },
    async fetchAudits (state) {
      const res = await fetch(base_url+'/platforms/audit',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setAudits",data)
    },
    async fetchUser (state, payload) {
      const res = await fetch(base_url+'/users/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setUser",data)
    },
    async fetchDispatchMaterials (state) {
      const res = await fetch(base_url+'/dispatchmaterial',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setDispatchMaterials",data)
    },
    async fetchSubsidiaries (state) {
      const res = await fetch(base_url+'/country/camusat',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setSubsidiaries",data)
    },
    async fetchMovementTeams (state, payload) {

      //Search for dispatch project using payload, then pick the client, subsidiary and teams from the dispatch project
      const res = await fetch(base_url+'/dispatchproject/'+ payload,{ mode: 'cors', headers})
      const data = await res.json()

      // Get camusatClient and camusatCountry from data.Project.mainProject
      const camusatClient = data.Project.mainProject.CamusatClient
      const camusatCountry = data.Project.mainProject.CamusatCountry
      const team = data.teams
      
      //Destructure camusatClient and camusatCountry and team into an array
      const movementTeams = [camusatClient, camusatCountry, ...team]  

      console.log('movementTeams', movementTeams)
      state.commit("setMovementTeams",movementTeams)
    },
    async fetchWorkOrder(state, payload) {
      const res = await fetch(base_url+'/workorders/workorder/'+payload,{ mode: 'cors', headers})
      const data = await res.json()

      const workOrderTeamLogs = data.workOrderActivityLogs.filter((log) => log.type === 'Team')
      workOrderTeamLogs.forEach((log) => {
        log.createdAt = new Date(log.createdAt).toLocaleString()
      });

      const workOrderCancellationlog = data.workOrderActivityLogs.filter((log) => log.statusUpdate === 'Cancelled')
      workOrderCancellationlog.forEach((log) => {
        log.createdAt = new Date(log.createdAt).toLocaleString()
      });

      const workOrderTasks = data.workOrderTasks.filter((task) => task.status === 'complete')
      console.log('workOrderTasks', workOrderTasks)
      workOrderTasks.forEach((task) => {
        task.updatedAt? task.updatedAt = new Date(task.updatedAt).toLocaleString() : task.updatedAt = null
        task.scheduledStartAt? task.scheduledStartAt = new Date(task.scheduledStartAt).toLocaleString() : task.scheduledStartAt = null
      });

      data.workOrderEditLogs.forEach((log) => {
        log.createdAt = new Date(log.createdAt).toLocaleString()
      })

      // Flatten ticket details to have data from data.data displayed as an array of objects, with the key as info and value as infoData
      // data.data = data.data.map((item) => {
      //   return {
      //     description: item.info,
      //     value: item.infoData
      //   }
      // })
      state.commit("setSelectedWorkOrderDetails",data.data)
      state.commit("setSelectedWorkOrderTeamLogs",workOrderTeamLogs)
      state.commit("setSelectedWorkOrderCancellationlog",workOrderCancellationlog)
      state.commit("setWorkOrder",data)
      state.commit("setSelectedWorkOrderTasks",workOrderTasks)
      state.commit("setSelectedWorkOrderEditLog",data.workOrderEditLogs)
    },
    async fetchWorkOrders (state) {
      const res = await fetch(base_url+'/workorders',{ mode: 'cors', headers})
      const data = await res.json()
      // console.log(data, typeof data)
      // Loop through data object to change createdAt and updatedAt attributes to locale date time
      data.forEach((element) => {
        if (element.scheduledStartAt && element.scheduledEndAt) {
          const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
          element.scheduledStartAt = new Date(element.scheduledStartAt).toLocaleString('en-KE', options)
          element.scheduledEndAt = new Date(element.scheduledEndAt).toLocaleString('en-KE', options)
        }
      });
      // Check the data key in every object in data for an object with an key info of attribute Client and log it
      data.forEach((element) => {
        if (element.data.length > 0) {
          const hasClient = element.data.some((item) => item.info === 'Client')
          if (hasClient) {
            // Append the value of dataInfo to element.projectName
            element.clientName = element.data.find((item) => item.info === 'Client').infoData
            // console.log(element.clientName)
          }
        }
      });
      // console.log(data)
      state.commit("setWorkOrders",data)
    },
    async fetchWorkOrderEditLogs (state, payload) {
      const res = await fetch(base_url+'/workorders/workorder/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      data.workOrderEditLogs.forEach((log) => {
        log.createdAt = new Date(log.createdAt).toLocaleString()
      });
      state.commit("setSelectedWorkOrderEditLog",data.workOrderEditLogs)
    },
    async fetchSelectedWorkOrder (state, payload) {
      const workOrderDetails = this.getters.getWorkOrders.find((workOrder) => workOrder.id === payload)
      const workOrderTeamLogs = workOrderDetails.workOrderActivityLogs.filter((log) => log.type === 'Team')
      workOrderTeamLogs.forEach((log) => {
        log.createdAt = new Date(log.createdAt).toLocaleString()
      });
      const workOrderCancellationlog = workOrderDetails.workOrderActivityLogs.filter((log) => log.statusUpdate === 'Cancelled')
      workOrderCancellationlog.forEach((log) => {
        log.createdAt = new Date(log.createdAt).toLocaleString()
      });

      // Flatten ticket details to have data from data.data displayed as an array of objects, with the key as info and value as infoData
      // workOrderDetails.data = workOrderDetails.data.map((item) => {
      //   return {
      //     description: item.info,
      //     value: item.infoData
      //   }
      // })
      state.commit("setSelectedWorkOrderDetails",workOrderDetails.data)
      state.commit("setSelectedWorkOrderTeamLogs",workOrderTeamLogs)
      state.commit("setSelectedWorkOrderCancellationlog",workOrderCancellationlog)
      // console.log('from mutation', workOrderDetails)
      state.commit("setSelectedWorkOrder", workOrderDetails)
      state.commit("setSelectedWorkOrderEditLog",workOrderDetails.workOrderEditLogs)
    },
    async fetchTicketsToSchedule (state, payload) {
      // Check for one or many work orders with ids in payload
      const ids = Array.isArray(payload) ? payload : [payload]

      const ticketsToSchedule = this.getters.getWorkOrders.filter((workOrder) => ids.includes(workOrder.id))

      // console.log('from mutation', ticketsToSchedule)
      state.commit("setTicketsToSchedule", ticketsToSchedule)
    },
    async fetchScheduledWorkOrders (state) {
      const res = await fetch(base_url+'/workorders/scheduled',{ mode: 'cors', headers})
      const data = await res.json()
      // Loop through data object to change createdAt and updatedAt attributes to locale date time
      data.forEach((element) => {
        if (element.scheduledStartAt && element.scheduledEndAt) {
          const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
          element.scheduledStartAt = new Date(element.scheduledStartAt).toLocaleString('en-KE', options)
          element.scheduledEndAt = new Date(element.scheduledEndAt).toLocaleString('en-KE', options)
        }
      });
      // Check the data key in every object in data for an object with an key info of attribute Client and log it
      data.forEach((element) => {
        if (element.data.length > 0) {
          const hasClient = element.data.some((item) => item.info === 'Client')
          if (hasClient) {
            // Append the value of dataInfo to element.projectName
            element.clientName = element.data.find((item) => item.info === 'Client').infoData
          }
        }
      });
      // console.log(data)
      state.commit("setScheduledWorkOrders",data)
    },
    async fetchWorkOrdersOnSchedule (state) {
      const res = await fetch(base_url+'/workorders/scheduled',{ mode: 'cors', headers})
      const data = await res.json()

      const filteredTickets = []
      data.forEach((workOrder) => {
        filteredTickets.push({
          id: workOrder.id,
          resourceId: workOrder.teamId,
          title: workOrder.mainId,
          start: workOrder.scheduledStartAt,
          end: workOrder.scheduledEndAt,
          color: workOrder.statusColour,
          textColor: '#202020',
          borderColor: '#202020'
        })
      })
      

    // console.log(typeof filteredTickets, "Scheduled Work Orders", filteredTickets);
    state.commit("setWorkOrdersOnSchedule",filteredTickets)
    },
    async fetchCancelTicketReasons (state) {
      const res = await fetch(base_url+'/problemCategorization/platformName/Dispatch/problemType/cancelTicket',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCancelTicketReasons",data)
    },
    async fetchRejectTicketReasons (state) {
      const res = await fetch(base_url+'/problemCategorization/platformName/Dispatch/problemType/rejectTicket',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setRejectTicketReasons",data)
    },
    async fetchStopTeamReasons (state) {
      const res = await fetch(base_url+'/problemCategorization/platformName/Dispatch/problemType/stopTeam',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setStopTeamReasons",data)
    },
    async fetchProblemCategorizations (state) {
      const res = await fetch(base_url+'/problemCategorization',{ mode: 'cors', headers})
      const data = await res.json()
      console.log(data)
      state.commit("setProblemCategorizations",data)
    },
    async fetchTicketProblems (state) {
      const res = await fetch(base_url+'/problemCategorization/platformName/Dispatch/problemType/problemWithTicket',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setTicketProblems",data)
    },
    async fetchWorkOrdersByProject (state, payload) {
      console.log('payload', payload)
      const res = await fetch(base_url+'/workorders/project/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      // Loop through data object to change createdAt and updatedAt attributes to locale date time
      data.forEach((element) => {
        if (element.scheduledStartAt && element.scheduledEndAt) {
          const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
          element.scheduledStartAt = new Date(element.scheduledStartAt).toLocaleString('en-KE', options)
          element.scheduledEndAt = new Date(element.scheduledEndAt).toLocaleString('en-KE', options)
        }
      });
      // Check the data key in every object in data for an object with an key info of attribute Client and log it
      data.forEach((element) => {
          const tasks = element.workOrderTasks.filter((task) => task.status === 'complete')
          element.task = tasks[tasks.length - 1]?.name || 'N/A'

        if (element.data.length > 0) {
          const hasClient = element.data.some((item) => item.info === 'Client')
          if (hasClient) {
            // Append the value of dataInfo to element.projectName
            element.clientName = element.data.find((item) => item.info === 'Client').infoData
          }
        }
      });
      console.log(data)
      state.commit("setWorkOrdersByProject",data)
    }
  },
  modules: {
  },
  getters: {
    getPlatforms : state => state.platforms,
    getTeams : state => state.teams,
    getUsers : state => state.users,
    getMainProjects : state => state.mainProjects,
    getProjects : state => state.projects,
    getProjectTypes : state => state.projectTypes,
    getDispatchProjects : state => state.dispatchprojects,
    getAudits : state => state.audits,
    getUser : state => state.user,
    getDispatchMaterials : state => state.dispatchMaterials,
    getSubsidiaries : state => state.subsidiaries,
    getMovementTeams : state => state.movementTeams,
    getWorkOrders : state => state.workorders,
    getSelectedWorkOrder : state => state.selectedWorkOrder,
    getTicketsToSchedule : state => state.ticketsToSchedule,
    getScheduledWorkOrders : state => state.scheduledWorkOrders,
    getCancelTicketReasons : state => state.cancelTicketReasons,
    getRejectTicketReasons : state => state.rejectTicketReasons,
    getStopTeamReasons : state => state.stopTeamReasons,
    getProblemCategorizations : state => state.problemCategorizations,
    getTicketProblems : state => state.ticketProblems,
    getWorkOrder: state => state.workOrder,
    getWorkOrderTeamLogs: state => state.selectedWorkOrderTeamLogs,
    getWorkOrderCancellationLog: state => state.selectedWorkOrderCancellationlog,
    getWorkOrderEditLog: state => state.selectedWorkOrderEditLog,
    getWorkOrderDetails: state => state.selectedWorkOrderDetails,
    getWorkOrderTasks: state => state.selectedWorkOrderTasks,
    getWorkOrdersByProject: state => state.workOrdersByProject,
    getMainProjectsBySubsidiary: state => state.mainProjectsBySubsidiary,
    getProjectsByMainProject: state => state.projectsByMainProject,
    getDispatchProjectsByMainProject: state => state.dispatchProjectsByMainProject,
    getWorkOrdersOnSchedule: state => state.workOrdersOnSchedule,
    getTeamsByDispatchProject: state => state.teamsByDispatchProject,
    getDispatchProjectByProject: state => state.dispatchProjectByProject
  }
})
