<template>
  <header id="header">
    <div class="container">
      <div class="row" v-if="loginHeader === true">
        <div class="col-md-6 align-self-center">
          <a href="#" class="logo">
            <img src="../assets/images/ticketflow_logo.png" alt="">
          </a>
        </div>
        <div class="col-md-6 align-self-center">
          <div class="select-languages">
           <div class="form">
             <select @change="switchLanguage($event.target.value)">
               <option value="en">English</option>
               <option value="fr">French</option>
             </select>
           </div>
            <div class="img-wrapper">
              <i class="fa-regular fa-circle-question" style="padding-top: 5px;"></i>
              <label style="padding:0px 10px; color: #000; font-size: 16px; font-weight: 400;">Help</label>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </header>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: ['loginHeader', 'mainHeader']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
